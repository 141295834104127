import React, { FC } from 'react'
import Page from 'modules/common/Page'
import LoginPageContent from 'modules/auth/LoginPageContent'

const B2CLoginPage: FC = () => (
	<Page layout="side" titleKey="login">
		<LoginPageContent />
	</Page>
)

export default B2CLoginPage
